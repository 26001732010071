import 'regenerator-runtime/runtime';
//import '@babel/polyfill';

/*
import './css/reset.css';
import './css/animate.css';
import './css/slick.css';
import './css/main.less';*/

import environment from './environment';
import {PLATFORM} from 'aurelia-pal';
//import * as Bluebird from 'bluebird';
import {I18N, TCustomAttribute} from 'aurelia-i18n';
import Backend from 'i18next-xhr-backend'; 

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
/*Bluebird.config({
	warnings: { wForgottenReturn: false },
    longStackTraces: false
});
*/

//import 'bootstrap/dist/css/bootstrap.css';
//import 'font-awesome/css/font-awesome.css';


export function configure(aurelia) {
	aurelia.use
		.standardConfiguration();

    aurelia.use.feature(PLATFORM.moduleName('resources/index'));

    //aurelia.use.plugin(PLATFORM.moduleName('aurelia-useable-style-loader'));

    aurelia.use.plugin(PLATFORM.moduleName('bcx-aurelia-reorderable-repeat'));

    aurelia.use.plugin(PLATFORM.moduleName("aurelia-inputmask"), { showMaskOnHover: false /* any default options */ });

	aurelia.use.plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
		let aliases = ['tr', 'i18n'];
		// add aliases for 't' attribute
		TCustomAttribute.configureAliases(aliases);

		// register backend plugin
		instance.i18next.use(Backend);

		// adapt options to your needs (see http://i18next.com/docs/options/)
		// make sure to return the promise of the setup method, in order to guarantee proper loading
		return instance.setup({
			backend: {                                  // <-- configure backend settings
				loadPath: './locales/{{lng}}/{{ns}}.json' // <-- XHR settings for where to get the files from
			},
			attributes: aliases,
			lng: 'en',
			//fallbackLng : 'en',
		    // allow keys to be phrases having `:`, `.`
			nsSeparator: false,
			keySeparator: false,
			//saveMissing: true,
			//missingKeyHandler: (lng, ns, key, fallbackValue) => {console.log('Missing key:', {lng, ns, key, fallbackValue}); return 'test';},
			parseMissingKeyHandler: (...args) => {
			    console.log('parseMissing:', args);
			    return false;
                return args[0];
            },
			//appendNamespaceToMissingKey: true,
			// do not load a fallback
			fallbackLng: false,
			debug: true
		});
	});



	// Uncomment the line below to enable animation.
	// aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
	// if the css animator is enabled, add swap-order="after" to all router-view elements

	// Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
	// aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

	environment.debug = true;

	aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

	if (environment.testing) {
		aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
	}

	return aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
