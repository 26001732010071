/**
 * Created by User on 027 27.01.22.
 */
import {backend, user} from './services/backend';

import {inject} from 'aurelia-framework';
import {Router, activationStrategy} from 'aurelia-router';
import {Countries, OwnershipTypes} from './services/languages';
//import * as $ from 'jquery';
//import 'select2/dist/css/select2.min.css';
//import 'select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';

let IBAN = require('iban');

@inject(Router)
export class PayoutFrame {
    request = {};
    countries = Countries;
    payout_files = [];
    offerError = "";
    is_upload_sucsessful = false;
    bankAccount = {
        Type: 'IBAN',
        OwnerAddress: {
            Country: ''
        }
    };
    types = {
        IBAN: {
            name: 'BIC & IBAN',
            fields: ['IBAN', 'BIC']
        },
        UK: {
            name: 'UK',
            fields: ['SortCode', 'AccountNumber']
        },
        US: {
            name: 'US',
            fields: ['AccountNumber', 'ABA']
        },
        CA: {
            name: 'CA',
            fields: ['BranchCode', 'InstitutionNumber', 'AccountNumber', 'BankName']
        },
        OTHER: {
            name: 'Other local',
            fields: ['Country', 'BIC', 'AccountNumber']
        }
    };


    constructor(router) {
        this.router = router;
        this.user = user;
        this.parent = router.container.viewModel;
        this.JSON = JSON;
        this.legal = "";
        this.formPayout = "";
        this.IBAN = "";
        this.BIC = "";
        this.OTHER = "";
    }


    activate(params) {
        //this.page = parseInt(params.page || '1');
        console.log('search.activate:', params);
        this.params = params;
    }

    async attached() {

        let payout = await backend
            .service('payout')
            .get(1);
        console.log('Payout:', payout);
        this.payout = payout || {};
        if (payout.bankAccountSave && !payout.bankAccount)
            payout.bankAccount = payout.bankAccountSave;
        if (!payout.bankAccount || !payout.bankAccount.Type) {
            payout.bankAccount = payout.bankAccount || {};
            payout.bankAccount.Type = 'IBAN';
        }
        if (!payout.bankAccount.OwnerName) {
            payout.bankAccount.OwnerName = [user.firstName, user.lastName].join(' ');
        }
        if (!payout.bankAccount.OwnerAddress) {
            payout.bankAccount.OwnerAddress = {Country: ''};
        }

        this.bankAccount = payout.bankAccount;
    }

    KYCFilesChange() {
        console.log('KYCFiles changed:', this.KYCFiles);
        const label = this.filesToUpload;
        const defaultLabelText = 'No file(s) selected';

        label.textContent = defaultLabelText;
        label.title = defaultLabelText;

        const filenameList = Array.prototype.map.call(this.KYCFiles, (file)=> {
            let filename_list = [];

            this.payout_files.push(file);

            if(this.payout_files.length > 2) {
                this.payout_files.shift();

                filename_list.push(this.payout_files[0].name);
                filename_list.push(this.payout_files[1].name);
            }
            else filename_list.push(this.payout_files[0].name);

            return filename_list;
        });

        label.textContent = filenameList.join(', ') || defaultLabelText;


    }


    async uploadKYC() {
        if (!this.payout_files || !this.payout_files.length) return;
        let formData = new FormData();

        for (let i = 0; i < this.payout_files.length; i++) {
            formData.append('kyc', this.payout_files[i]);
        }

        let d= 0;

        return $.post({
            //type: "POST",
            url: '/upload/KYC/',
            data: formData,
            processData: false,
            contentType: false,
            success: (r) => {
                if(r.status === undefined) {
                   this.offerError = " Documents are under consideration";
                   this.is_upload_sucsessful = true;
                }
                else {
                    this.offerError = r.msg;
                    this.is_upload_sucsessful = false;
                }
                $('#offerModal').modal('show');
/*
                for (let i = 0; i < r.length; i++) {
             images[i].src = r[i];
             delete images[i].file;
*/
            // }
             //this.apartment.data.images = r;
             //this.images = null;
             //this.imagesPreview = [];
             //cb();
             },
             error: (e) => {
             console.log('some error', e);
             this.error.validation = e.message;
             }/**/
        });
    }
    async closeModal() {
        //

        let idx = user.apartments.length - 1;

        if(this.is_upload_sucsessful)
            window.location.href = '/apartment/' + user.apartments[idx].id + '/preview';
        else
            $('#offerModal').modal('hide');
        //return payout;
    }

    async savePayout() {
        console.log("Form Payout", this.formPayout);
        let swift = new RegExp(/^[a-zA-Z]{6}\w{2}(\w{3})?$/);
        let checkFormValidity = () => {
            if (!this.formPayout.checkValidity()) {
                this.formPayout.reportValidity();
            }
        };
        let validateIBAN = () => {
            if (!IBAN.isValid(this.IBAN.value)) {
                this.IBAN.style.borderColor = 'red';
                this.IBAN.setCustomValidity("Please type the correct IBAN");
                return false;
            }
            else {
                this.IBAN.style.borderColor = '#DBE0E4';
                this.IBAN.setCustomValidity("");
                checkFormValidity();
                return true;
            }
        };

        let validateSwift = () => {
            if (!swift.test(this.BIC.value)) {
                this.BIC.style.borderColor = 'red';
                this.BIC.setCustomValidity("Please type the correct BIC");
                return false;
            }
            else {
                this.BIC.style.borderColor = '#DBE0E4';
                this.BIC.setCustomValidity("");
                checkFormValidity();
                return true;
            }
        };


        checkFormValidity();


        // if (this.OTHER && this.OTHER.value.trim() === '') {
        //     if (!IBAN.isValid(this.IBAN.value)) {
        //         this.IBAN.style.borderColor = 'red';
        //         this.IBAN.setCustomValidity("Please type the correct IBAN");
        //         // this.IBAN.value = "";
        //         return false;
        //     }
        //     else if (!swift.test(this.BIC.value)) {
        //         this.BIC.setCustomValidity("Please type the correct IBAN");
        //         this.BIC.value = "";
        //         return false;
        //     }
        //     else {
        //         this.IBAN.style.borderColor = '#DBE0E4';
        //         this.BIC.style.borderColor = '#DBE0E4';
        //     }
        // }
        if (this.OTHER && !this.OTHER.value.trim()) {
            validateIBAN();
            if (validateIBAN()) {
                validateSwift();
            }

        }
        if (!this.OTHER) {
            if (validateIBAN()) {
                validateSwift();
            }
        }


        this.errors = undefined;
        console.log('Uploading KYC:', await this.uploadKYC());
        if (this.formPayout.reportValidity()) {
            await backend
                .service('payout')
                .update(1, this.bankAccount)
                .then((u) => {
                    console.log('Payout update result:', u);
                    if (u.errors) {
                        this.payout = u;
                        return;
                    }
                    if (this.params.r) {
                        this.router.navigate(this.params.r);
                    } else {
                        this.attached();
                        this.parent.alert('Payout saved', 'success', true, 4000);
                    }
                });
        }
    }

    // This is necessary to tell Aurelia router not to reuse
    // the same view model whenever navigating between pages
    // so that the activate method gets called each time
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    updatePage(event) {
        console.log(event.target.model);
        let type = event.target.model;
        if (type) {
            this.legal = true;
            console.log(this.legal);
        }
        else {
            this.legal = false;
        }
    }

}