import {backend, user} from './services/backend';

import {inject} from 'aurelia-framework';
import {Router, activationStrategy} from 'aurelia-router';
import {Countries, OwnershipTypes} from './services/languages';
// import {PayoutFrame} from './payout-frame';
//import * as $ from 'jquery';


@inject(Router, )
export class Payout {
    constructor(router) {
        this.router = router;
        this.user = user;
        this.parent = router.container.viewModel;
        this.JSON = JSON;
        this.legal = "";
        this.formPayout = "";
        this.IBAN = "";
        this.BIC = "";
        this.OTHER = "";
    }
}
