const currencies = {
	EUR: {
		symbol: "€",
		caption: "Euro",
		class: "i-euro"
	},
	USD: {
		symbol: "$",
		caption: "US Dollar",
		class: "i-dollar"
	},
	GBP: {
		symbol: "£",
		caption: "Pound Sterling",
		class: "i-pound-sterling"
	}
};

export { currencies };
