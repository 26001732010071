import {backend, /*backend,*/ user} from './services/backend';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';

@inject(Router)
export class Book {
	constructor(router) {
		this.router = router;
		this.parent = router.container.viewModel;
	}

	activate(params, routeConfig) {
		this.params = params;
	}

	async save() {
		console.log("Saving...");
		if (!this.form.checkValidity()) {
			this.form.reportValidity();
			console.log('invalid')
		} else {
			this.paymentErrors = '';

			await this.profile.save();

			if (!user.userData.mangoId) {
				this.paymentErrors = user.userData.data.error;
				return;
			}

			return this.router.navigateToRoute('payment', {requestId: this.params.requestId, offerId: this.params.offerId});
			/*backend
				.service('payment')
				.create({ id: this.params.offerId, lang: this.parent.locale, url: window.location.origin + `/request/${this.params.requestId}/${this.params.offerId}/` })
				.then((data) => {
					console.log('Payment created:', data);
					if (data.error && data.error.errors)
						this.paymentErrors = JSON.stringify(data.error.errors, 0, '\t');
					if (data.url) {
						window.location = data.url;
					}
				});*/
		}
	}
}
