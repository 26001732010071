/* eslint-disable curly */
import {backend, user} from './services/backend';
import {Router} from 'aurelia-router';
import {inject} from 'aurelia-framework';

@inject(Router)
export class Login {
	constructor(router) {
		this.router = router;
		if (router.container && router.container.viewModel) {
			this.parent = router.container.viewModel;
		}
		this.user = user;
	}

	navigate() {
	    if (this.params.r)
	        this.router.navigate(this.params.r);
		else
			this.router.navigate('/');
	}

	onLogin = (user) => {
	    console.log('On Login:', user);
		if (user.id) {
			this.navigate();
		}
	}

	activate(params) {
		console.log('login.activate:', params);
		this.params = params;
		this.parent.headerCss = 'header-details';
		this.parent.dark = true;
		this.parent.footerClass = 'fixed-bottom';
		backend.on('user', this.onLogin);
	}

	deactivate() {
	    backend.off('user', this.onLogin);
	}

	login() {
		this.parent.login().then(this.navigate);
	}

	register() {
		this.parent.register().then(this.navigate);
	}
}
