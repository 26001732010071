import {Router} from 'aurelia-router';
import {inject} from 'aurelia-framework';

@inject(Router)
export class FAQ {
	constructor(router) {
		this.router = router;
		if (router.container && router.container.viewModel) {
			this.parent = router.container.viewModel;
		}
	}

	activate(params) {
		console.log('help.activate:', params);
		this.params = params;
		this.parent.headerCss = 'header-details';
		this.parent.dark = true;
		//this.parent.footerClass = 'fixed-bottom';
	}
}
