import {backend, /*backend,*/ user} from './services/backend';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';

@inject(Router)
export class Payment {
	constructor(router) {
		this.router = router;
		this.parent = router.container.viewModel;
	}

	activate(params, routeConfig) {
		this.params = params;
	}

	async book(platform) {
	    backend
			.service('payment')
			.create({
				id: this.params.offerId,
				lang: this.parent.locale,
				url: window.location.origin + `/request/${this.params.requestId}/${this.params.offerId}/`,
				platform
			})
			.then((data) => {
				console.log('Payment created:', data);
				if (data.error && data.error.errors) {this.paymentErrors = JSON.stringify(data.error.errors, 0, '\t');}
				if (data.url) {
					window.location = data.url;
				}
			});
	}
}
